<template>
    <div class="main-container">
        <router-view></router-view>
    </div>
</template>

<style scoped>
.main-container{
    padding: 0px;
    /* position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px; */
}
</style>