<template>
  <div class="fs-footer fs-flex fs-flex-between fs-flex-align-center"
    :class="[(screenWidth < 850 && 'fs-flex-column') || '', (screenWidth < 850 && 'fs-flex-align-baseline') || '']">
    <!-- logo -->
    <!-- <div class="logo">
      <img src="@/assets/icon/logo-grey.png" />
    </div> -->
    <!-- 底边栏 -->
    <div class="links fs-flex fs-flex-between" :class="[(screenWidth < 500 && 'fs-flex-column') || '']">
      <!-- 线上采购 -->
      <!-- <div style="margin-right: 30px; cursor: pointer" @click="handleOnline">
        <p>线上采购</p>
        <div>
          <div class="fs-icon">
            <a><img src="@/assets/icon/online.png" /></a>
          </div>
        </div>
      </div> -->
      <!-- 关于富胜 -->
      <div>
        <div class="fs-itembar fs-flex">
          <!-- <p>线上采购</p> -->

          <el-popover placement="top" popper-class="qr-popover-first">
            <template #reference>
              <a target="_blank" :href="buyOnline?.link">
                <div class="about-item">
                  <div class="fs-icon">
                    <a><img class="online" src="@/assets/icon/online.png" /></a>
                  </div>
                </div>
              </a>
            </template>
            <div style="width: 100%; display: flex; justify-content: center;margin-top: -2px;font-size: 13px;">
              <p>线上采购</p>
            </div>
          </el-popover>


          <el-popover class="fs-popover" placement="top" popper-class="qr-popover">
            <template #reference>
              <div class="about-item">
                <div class="fs-icon">
                  <a><img class="wechat" src="@/assets/icon/wechat.png" /></a>
                </div>
              </div>
            </template>
            <img src="@/assets/icon/wechat-qr.png" />
            <div style="width: 100%; display: flex; justify-content: center;margin-top: -7px;font-size: 13px;">
              <p>微信公众号</p>
            </div>
          </el-popover>


          <el-popover placement="top" popper-class="qr-popover">
            <template #reference>
              <div class="about-item">
                <div class="fs-icon">
                  <a><img class="video" src="@/assets/icon/video.png" /></a>
                </div>
              </div>
            </template>
            <img src="@/assets/icon/video-qr.png" />
            <div style="width: 100%; display: flex; justify-content: center;margin-top: -7px;font-size: 13px;">
              <p>视频号</p>
            </div>
          </el-popover>


          <el-popover placement="top" popper-class="qr-popover">
            <template #reference>
              <div class="about-item">
                <div class="fs-icon">
                  <a><img class="tiktok" src="@/assets/icon/tiktok.png" /></a>
                </div>
              </div>
            </template>
            <img src="@/assets/icon/tiktok-qr.png" />
            <div style="width: 100%; display: flex; justify-content: center;margin-top: -7px;font-size: 13px;">
              <p>抖音</p>
            </div>
          </el-popover>


          <el-popover placement="top" popper-class="qr-popover">
            <template #reference>
              <div class="about-item">
                <div class="fs-icon">
                  <a><img class="redbook" src="@/assets/icon/redbook.png" /></a>
                </div>
              </div>
            </template>
            <img src="@/assets/icon/redbook-qr.png" />
            <div style="width: 100%; display: flex; justify-content: center;margin-top: -7px;font-size: 13px;">
              <p>小红书</p>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="statements">
      <div class="statements-box">

        <div style="width:auto;margin:0 10px; padding:10px 0;">
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;font-size: 14px;">
            版权所有: 北京富胜家居用品有限公司 CNSC Fortune Hardware Co., Ltd.
          </p>
        </div>

        <!-- <div style="width:230px;margin:0 10px; padding:10px 0;">
          <a target="_blank" href=" http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=123456789"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;display: flex;align-items: center;">
            <img src="@/assets/icon/beian.png" style="float:left;width: 14px;height: 14px;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;font-size: 14px;">
              网安备 123456789号
            </p >
          </a>
        </div> -->

        <div style="width:auto;margin:0 10px; padding:10px 0;">
          <a target="_blank" href="https://beian.miit.gov.cn/"
            style="text-decoration:none;height:20px;line-height:20px;">
            <p class="icp"
              style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;font-size: 14px;">
              京ICP备11008789号-4
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import request from "@/utils/request";
export default {
  name: "Footer",
  data() {
    return {
      opacity: 0,
      drawer: false,
      buyOnline: {},
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),
  },
  mounted() {
    this.getBuyonline();
  },
  methods: {
    handleOnline() {
      window.open("https://www.baidu.com", "_blank");
    },

    handleScroll() {
      const top = document.documentElement.scrollTop; //获取scroll偏移值
      if (top > 0 && top <= 76) {
        //实际按需求取范围
        this.opacity = top / 76; //对opacity作计算，透明度从起始到1随偏移值而改变
      }
    },
    getBuyonline() {
      request({
        url: "/api/ui/buyonline",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.buyOnline = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.fs-footer {
  // position:fixed;
  // bottom: -234px;
  // left: 0px;
  // right: 0px;

  height: 234px;
  max-height: 234px;
  background-color: #ececec;
  padding: 0px 0 0 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  row-gap: 60px;

  @media screen and (max-width: 850px) {
    padding: 60px 0 60px 0;
    height: auto;
    max-height: 600px;
  }

  .logo {
    width: 137px;
    height: 42px;
  }

  .fs-itembar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 32px;
  }

  .fs-icon {
    background-color: transparent;

    a {
      display: flex;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  .about-item {
    width: 47px;
    height: 47px;
    border: 1px solid #757575;
    border-radius: 50%;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .about-item:hover {
    background-color: #029fe7;
    border: 0px;

    cursor: pointer;

    img.online {
      content: url(../assets/icon/online-actived.png);
    }

    img.wechat {
      content: url(../assets/icon/wechat-actived.png);
    }

    img.video {
      content: url(../assets/icon/video-actived.png);
    }

    img.tiktok {
      content: url(../assets/icon/tiktok-actived.png);
    }

    img.redbook {
      content: url(../assets/icon/redbook-actived.png);
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.links {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  padding: 0 90px;

  @media screen and (max-width: 850px) {
    justify-content: center;
    padding: 0 40px;
  }
}

.statements {
  width: 100%;
  background-color: #E4E4E4;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  padding: 0px;


  .statements-box {
    width: 100%;
    max-width: 1920px;
    height: 50px;
    padding: 0 262px;
    // margin-top: -140px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 850px) {
      justify-content: center;
      padding: 0 40px;
      margin-top: 0px;
    }

    span {
      font-size: 14px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #A3A3A3;
    }
  }
}

.icp:hover {
  text-decoration: underline;
}
</style>
<style>
.qr-popover {
  min-width: 80px !important;
  width: 80px !important;
  height: 94px !important;
  padding: 5px !important;
}

.qr-popover-first {
  min-width: 80px !important;
  width: 80px !important;
  height: 26px !important;
  padding: 5px !important;
}
</style>
