<template>
  <div class="fs-header header-background fs-flex fs-flex-center" :style="{ background: backgroundCollor }">
    <div class="fs-flex fs-flex-between" style="width:100%; height:100%; max-width:1920px;">
      <!-- logo -->
      <div class="logo">
        <template v-if="lightMode">
          <img src="@/assets/icon/logo-grey.png" />
        </template>
        <template v-else>
          <img src="@/assets/icon/logo-white.png" />
        </template>
      </div>
      <!-- 菜单 -->
      <div class="fs-menu">
        <!-- 顶部菜单 -->
        <div v-if="screenWidth > 850" class="fs-wrap fs-flex fs-flex-between fs-flex-align-center" :class="detailMenu && 'detailMenu'">
          <a :class="[currentPageName == 'home' ? 'menu-item-active' : 'menu-item', lightMode ? 'menu-light-mode' : undefined]" href="/home">首页</a>
          <!-- <a :class="[currentPageName == 'aboutus' ? 'menu-item-active' : 'menu-item', lightMode ? 'menu-light-mode' : undefined]" href="/about">关于富胜</a> -->
          <div class="menu-item-group" :class="[currentPageName == 'aboutus' ? 'menu-item-active' : 'menu-item', lightMode ? 'menu-light-mode' : undefined]">
            <a href="/about">关于富胜</a>
            <div v-if="currentPageName != 'aboutus'" class="sub-menu">
              <div class="sub-menu-bar">
                <div class="sub-menu-container contact-menu-bar">
                  <a class="sub-menu-item" href="/about#introduction">
                    企业简介
                  </a>
                  <!-- <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/about#serveCustomer">
                    服务客户
                  </a> -->
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/about#brand">
                    旗下品牌
                  </a>
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/about#historys">
                    发展历程
                  </a>
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/about#consulting">
                    企业资讯
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div class="menu-item-group" :class="[currentPageName == 'product' ? 'menu-item-active' : 'menu-item', lightMode ? 'menu-light-mode' : undefined]">
            <a href="/product">产品中心</a>
            <!-- 产品中心·二级菜单 -->
            <div v-if="currentPageName != 'product'" class="sub-menu">
              <div class="product-types-menu sub-menu-bar">
                <div class="sub-menu-container" style="max-width: 2180px;">
                  <!-- 分类 -->
                  <div class="header-product-classify fs-flex fs-flex-between">
                    <div class="header-product-classify-action classify-action-left fs-flex fs-flex-align-center" @click="currentSwiper.slidePrev()">
                      <el-icon>
                        <ArrowLeft />
                      </el-icon>
                    </div>
                    <div class="header-product-type-bar swiper-container" id="product-types-swipper">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide" style="width: 137px;margin-right: 0px;" v-for="(type, index) in productTypeList" :key="index">
                          <div class="mask-container">
                            <div class="header-product-type-box">
                              <a :href="'/product?type='+type.path" >
                                <svg-image :src="'/api/ffs/file/'+type.image.id" :label="type.name" :width="60" :height="60" :color="lightMode ? '#727171': '#b6b6b6'" :hover-color="lightMode ?'#000000': '#ffffff'"></svg-image>
                              </a>
                            </div>
                          </div>
                          <el-divider v-if="index > 0" class="menu-item-divider" direction="vertical" />
                        </div>
                      </div>
                    </div>
                    <div class="header-product-classify-action classify-action-right fs-flex fs-flex-align-center" @click="currentSwiper.slideNext()">
                      <el-icon>
                        <ArrowRight />
                      </el-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-item-group" :class="[currentPageName == 'service' ? 'menu-item-active' : 'menu-item', lightMode ? 'menu-light-mode' : undefined]">
            <a href="/server">服务中心</a>
            <div v-if="currentPageName != 'service'" class="sub-menu">
              <div class="sub-menu-bar">
                <div class="sub-menu-container contact-menu-bar">
                  <a class="sub-menu-item" href="/server#book">
                    电子图册
                  </a>
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/server#online">
                    线上采购
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="menu-item-group" :class="[currentPageName == 'contact' ? 'menu-item-active' : 'menu-item', lightMode ? 'menu-light-mode' : undefined]">
            <a href="/contact">联系我们</a>
            <div v-if="currentPageName != 'contact'" class="sub-menu">
              <div class="sub-menu-bar">
                <div class="sub-menu-container contact-menu-bar">
                  <a class="sub-menu-item" href="/contact#area">
                    全国销售区域
                  </a>
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/contact#hall">
                    全国展厅
                  </a>
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/contact#enginer">
                    联系销售工程师
                  </a>
                  <el-divider class="menu-item-divider" direction="vertical" />
                  <a class="sub-menu-item" href="/contact#sales">
                    经销商查询
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 侧边菜单 -->
        <div class="fs-menu-mobile fs-flex fs-flex-align-center" v-else>
          <div class="mobile-wrap">
            <el-icon @click="drawer = true">
              <Menu />
            </el-icon>
          </div>
        </div>
        <el-drawer v-model="drawer" title="I am the title" size="100%" :show-close="false">
          <template #header="{ close }">
            <div class="fs-flex fs-flex-between">
              <div class="logo"><img src="@/assets/icon/logo-grey.png" /></div>
              <div class="mobile-wrap fs-flex fs-flex-align-center">
                <el-icon @click="close">
                  <CloseBold />
                </el-icon>
              </div>
            </div>
          </template>
          <el-menu style="border-right: 0" router @select="drawer = false">
            <el-menu-item index="/home">
              <span>首页</span>
            </el-menu-item>
            <el-menu-item index="/about">
              <span>关于富胜</span>
            </el-menu-item>
            <el-menu-item index="/product">
              <span>产品中心</span>
            </el-menu-item>
            <el-menu-item index="/server">
              <span>服务中心</span>
            </el-menu-item>
            <el-menu-item index="/contact">
              <span>联系我们</span>
            </el-menu-item>
          </el-menu>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import {reactive} from "vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import request from "@/utils/request";
// import { useRouter } from "vue-router";

const MAX_HEADER_OPACITY = 0;
const MIN_HEADER_OPACITY = 0.5;
const OPACITY_TRANS_BOUNDARY = 400;

export default {
  name: "Header",
  data() {
    return {
      opacity: MAX_HEADER_OPACITY,
      drawer: false,
      dark: false,
      detailMenu: false,

      productTypeList: [],// 产品分类列表；
    };
  },
  created() {
    window.addEventListener("scroll", this.updateOpacity);
    // 获取path
    // if (this.$route.path.indexOf("pdf") > -1) {
    //   this.dark = false;
    // } else {
    //   this.dark = true;
    // }
    if (this.$route.path.indexOf("product") > -1) {
      this.detailMenu = true;
    } else {
      this.detailMenu = false;
    }
  },
  inject: ["pageIndicator"],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),

    currentPageName() {
      const pageIndicator = this.pageIndicator;
      return pageIndicator.currentPageName;
    },

    /**
     * 是否为浅色模式；
     */
    lightMode() {
      const pageIndicator = this.pageIndicator;
      if (pageIndicator.lightMode != undefined) {
        console.warn("lightMode=", pageIndicator.lightMode);
        return pageIndicator.lightMode;
      }
      return pageIndicator.currentPageName == "service" || pageIndicator.currentPageName == "contact";
    },

    headerGradient() {
      return this.pageIndicator.headerGradient === true;
    },

    headerTransparent() {
      return this.pageIndicator.headerTransparent === true;
    },

    backgroundCollor() {
      if (this.lightMode) {
        if (this.headerGradient) {
          // 背景渐变；
          return "rgba(241,241,241," + this.opacity + ")";
        } else {
          // 背景透明度不变；
          if (this.headerTransparent) {
            return "rgba(241,241,241,0)";
          } else {
            return "rgba(241,241,241," + MIN_HEADER_OPACITY + ")";
          }
        }
      } else {
        if (this.headerGradient) {
          // 背景渐变；
          return "rgba(0,0,0," + this.opacity + ")";
        } else {
          // 背景透明度不变；
          if (this.headerTransparent) {
            return "rgba(0,0,0,0)";
          } else {
            return "rgba(0,0,0," + MIN_HEADER_OPACITY + ")";
          }
        }
      }
    },
  },

  mounted() {
    this.updateOpacity();

    this.getProductType();

    setTimeout(() => {
      this.initSwiper();
      console.log("[header] postinit swiper ...")
    }, 1600);

  },

  methods: {
    updateOpacity() {
      const top = document.documentElement.scrollTop; //获取scroll偏移值

      const maxOpacity = MAX_HEADER_OPACITY;
      const minOpacity = MIN_HEADER_OPACITY;
      if (top == 0) {
        this.opacity = maxOpacity;
      }
      if (top > 0 && top <= OPACITY_TRANS_BOUNDARY) {
        //实际按需求取范围
        this.opacity = (minOpacity * top) / OPACITY_TRANS_BOUNDARY; //对opacity作计算，透明度从起始到1随偏移值而改变
      }
      if (top > OPACITY_TRANS_BOUNDARY) {
        this.opacity = minOpacity;
      }

      return this.opacity;
    },


    initSwiper(){
      const THIS = this;
      const init =  function(){
                      return new Swiper("#product-types-swipper", {
                      effect: 'slides',
                      autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
                      slidesPerView: 'auto', //视口展示
                      slidesPerGroup: 1, //触发一次滑动时，滑过去的个数；
                      slidesPerGroupAuto: false,
                      
                      // centeredSlides:true,
                      // centeredSlidesBounds:true,
                      // updateOnWindowResize:true,
                      // spaceBetween: 50,
                      observer: true, 
                      observeParents: true,

                     

                    });
      };
      this.currentSwiper = init();

      window.addEventListener('resize', function(){
        THIS.currentSwiper = init();
      });
    },

        // 获取一级分类
    getProductType() {
      request({
        url: "/api/ui/productType/list",
        method: "get",
      })
        .then((resp) => {
          console.info("收到产品一级分类列表！ -- ", resp);
          if (resp.success) {
            this.productTypeList = resp.data;
          }
          else {
            console.error("查询产品一级分类列表时发生错误！ --" + resp.message, "   response: ", resp);
            return Promise.reject(resp.message)
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // routeToProduct(type){
    //   console.log("route to product.... ", type);
    //   this.$router.push({
    //     name:'product',
    //     params:{
    //       type: JSON.stringify(type)
    //     }
    //   })
    // }
  },
};
</script>
<style lang="less">
.fs-header {
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 5000;
  padding: 17px 92px;
  transition: all 0.3s;

  .logo {
    width: 137px;
    height: 42px;
  }

  .fs-wrap {
    height: 100%;

    // a {
    //   margin: 0 20px;
    // }
  }

  .fs-menu-mobile {
    height: 100%;

    .mobile-wrap {
      color: #ffffff;
      font-size: 29px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.header-background {
  padding: 17px 92px;

  @media screen and (max-width: 850px) {
    padding: 17px 40px;
  }
}

.menu-item {
  color: #b6b6b6;
  font-weight: 400;
  padding: 8px 20px;

  position: relative;

  a {
    color: #b6b6b6;
    font-weight: 400;
  }

  :hover{
    color: #ffffff;
    font-weight: 500;
  }
}

a.menu-item:hover{
  color: #ffffff;
  font-weight: 500;
}

.menu-item-active {
  color: #ffffff;
  font-weight: 500;
  padding: 8px 20px;

  position: relative;

  > a {
    color: #ffffff;
    font-weight: 500;
  }
}

.menu-item.menu-light-mode {
  color: #727171;
  
  a {
    color: #727171;
  }
}
.menu-item-active.menu-light-mode {
  color: #000000;

  a {
    color: #000000;
  }
}

.sub-menu {
  width: 100%;

  z-index: 10000;

  visibility: hidden;

  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 30px;
  left: 0px;
  right: 0px;
  padding: 60px 10px 0 10px;

}

.sub-menu-container{
  width:100%;
  height: 100%;
  max-width: 2020px;
}

.sub-menu-bar{
  position: fixed;
  top:70px;
  left: 0px;
  right: 0px;
  height: 70px;
  width: 100%;
  padding: 0 40px;

  background-color: rgba(0, 0, 0, 0.6);
  border-top-color: rgba(241, 241, 241, 0.05);
  border-top-style: solid;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sub-menu-item {
  color: #b6b6b6;
  font-weight: 400;
  margin: 0 70px;

}
.sub-menu-item:hover{
  // color: #ffffff;
  font-weight: 400;
}

.menu-light-mode .sub-menu-bar {
  color: #727171;
  background-color: rgba(241,241,241, 0.2);
  a {
    color: #727171;
  }
}

.menu-light-mode .sub-menu-item:hover{
  color: #000000;
}


.menu-item:hover > .sub-menu {
  display: flex;
  visibility: visible;
}

.menu-item-active:hover > .sub-menu {
  display: flex;
  visibility: visible;
}

.sub-menu:hover {
  display: flex;
  visibility: visible;
}

/** 产品中心·二级菜单 */

.product-types-menu{
  height: 181px;
}

.contact-menu-bar{
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px 0;

}

.swiper-container {
  height: 100% !important;
}

.swiper-slide {
  height: 100%;
}

.mask-container {
  position: relative;
  padding: 0px;
  height: 100%;
}


.header-product-type-bar {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  //padding: 6px 0 0 0;
  margin: 6px 1px 0px 1px;

  .swiper-wrapper {
    width: 100% !important;
    max-height: 90px;
    min-height: 90px;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1550px) {
    .swiper-wrapper {
      justify-content: flex-start;
    }
  }
}

.menu-item-divider {
  width: 2px;
  padding: 0px;
  //margin: 0 0 2px 0;
  margin: -40px 0 2px 0;
  height: 0.9em;

  border-left: 1px solid #b6b6b6;
}

.header-product-type-bar-divider.el-divider--horizontal {
  margin: 0;
}


.header-product-type-box {
  width: 137px;
  color: rgba(88, 88, 88, 1);
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}

.header-product-type-name {
  font-size: 16px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  margin-top: 2px;

  color: #b6b6b6;
}



.menu-light-mode .header-product-type-box {
  color: #727171;
  a {
    color: #727171;
  }
}


.header-product-type-logo {
  height: 60px;

  img {
    height: 100%;
    width: auto;
  }
}

.header-product-classify {
  width: 100%;
  padding: 20px 20px;
  
  .header-product-classify-action {
    font-size: 20px;
    
    cursor: pointer;
    visibility: hidden;
  }

  .classify-action-left{
    padding: 0 40px 0 30px;
  }

  .classify-action-right{
    padding: 0 30px 0 40px;
  }

  @media screen and (max-width: 500px) {
    .header-product-classify-action {
      padding: 0 20px;
    }
  }
}

.header-product-classify:hover {
  .header-product-classify-action {
    visibility: visible;
  }
}

</style>
